<template>
   <payment-plans-component />
</template>

<script>
import PaymentPlansComponent from '../components/payment-plans/PaymentPlansComponent.vue';

export default {
  name: 'Cabinet',
  components: {
    PaymentPlansComponent
  },
};
</script>

<style >
body,html{
  overflow: hidden!important;
}
</style>
